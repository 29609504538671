<template>
  <div class="install">
    <div class="title">
      <h1>安装Transgle，享受回国</h1>
    </div>
    <p class="desc">下载Transgle，立即体验回国加速，帮你访问和加速爱奇艺、哔哩哔哩、腾讯视频、QQ音乐和网易云音乐等国内服务，不管身在何处，距离不再遥远。</p>
    <div class="tool_b_box_content" v-if="toolList.length>0">
      <div v-for="(item) in toolList" :key="item.version">
        <button class="i_install">
          <img class="android_logo" src="../../assets/iphone.png" />
          <span @click="openDownloadUrl(item.url)">定制APP</span>
        </button>
      </div>
    </div>
    <div class="tool_b_box_content" v-else>
      <button class="i_install" @click="openDownloadUrl('https://transgle-1302792139.cos.ap-hongkong.myqcloud.com/apk/app-release.apk')">
        <img class="android_logo" src="../../assets/iphone.png"/>
        <span>Android版</span>
      </button>
    </div>
    <router-link to="/recharge">
      <button class="i_buy">
        <img style='width:28px;' src="../../assets/Router.png" />
        <span>天翼网盒</span>
      </button>
    </router-link>
    <img class="i_platform" src="../../assets/platform_device.png" alt />
  </div>
</template>
<script>
import { getToolListApi } from "api/Android";

export default {
  name: "accelerate",
  data() {
    return {
      toolList: []
    };
  },
  mounted() {
    getToolListApi().then(res => {
      if (res.code === 0) {
        this.toolList = res.data;
      }
    });
  },
  methods: {
    openDownloadUrl(url) {
      window.open(url, "_blank");
    }
  }
};
</script>
<style lang="scss" scoped>
.install {
  background: #e3f6ff;
  padding: 0 16px 56px 16px;
  text-align: center;
  .title {
    display: flex;
    justify-content: center;
    h1 {
      line-height: 35px;
      text-align: justify;
      font-size: 26px;
      color: #202020;
      padding: 30px 0;
      font-weight: 500;
    }
  }
  .desc {
    font-size: 16px;
    color: #4a4a4a;
    line-height: 28px;
    text-align: justify;
  }
  button {
    width: 70%;
    margin: 0 auto;
    height: 54px;
    background: #517cfc;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 5px;
    color: #fff;
  }
  .i_install {
    vertical-align: middle;
    span {
      display: inline-block;
      vertical-align: middle;
      margin-top: 3px;
    }
    .android_logo {
      width: 24px;
      padding-right: 20px;
      vertical-align: middle;
    }
    margin-top: 58px;
  }
  .i_buy {
    display:flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 45px;
    span{
      margin-left:20px;
    }
  }
  .i_platform {
    width: 80%;
  }
}
</style>